import '../index.css'
import footer_image from '../Assets/footer_image.png'

export default function Footer() {
    return (
      <footer className="bg-custom-gradient py-12 md:px-12 px-4 lg:px-24">
      <div className="container mx-auto px-8">
        <div className="flex flex-col md:items-start md:flex-row justify-between items-center space-y-8 md:space-y-0">

          <div className="flex-1 text-center md:text-left md:mr-12">
            <img src={footer_image} alt="logo" className="w-32 md:w-auto mx-auto md:mx-0"/>
          </div>
    
          <div className="flex-1 text-center md:text-left">
            <p className="uppercase text-black font-bold mb-4">Links</p>
            <ul className="list-none space-y-2">
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">FAQ</div>
              </li>
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">Help</div>
              </li>
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">Support</div>
              </li>
            </ul>
          </div>
    
          <div className="flex-1 text-center md:text-left">
            <p className="uppercase text-black font-bold mb-4">Legal</p>
            <ul className="list-none space-y-2">
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">Terms</div>
              </li>
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">Privacy</div>
              </li>
            </ul>
          </div>

          <div className="flex-1 text-center md:text-left">
            <p className="uppercase text-black font-bold mb-4">Social</p>
            <ul className="list-none space-y-2">
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">Facebook</div>
              </li>
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">Instagram</div>
              </li>
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">LinkedIn</div>
              </li>
            </ul>
          </div>
    
          <div className="flex-1 text-center md:text-left">
            <p className="uppercase text-black font-bold mb-4">Company</p>
            <ul className="list-none space-y-2">
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">About Us</div>
              </li>
              <li>
                <div className="text-gray-800 hover:text-pink-500 transition-colors">Contact</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    )
}