import { useEffect } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../Util/Firebase";

export default function ConfirmBookingPage() {

    const location = useLocation();
    const navigate = useNavigate();
    const { selectedTanggal, selectedDay, selectedJam, selectedLayanan, harga } = location.state || {};
  
    useEffect(() => {
      const uid = localStorage.getItem('uid');
  
      if (uid === null) {
        navigate('/signin');
        return;
      }
  
      if (!selectedTanggal || !selectedDay || !selectedJam || !selectedLayanan) {
        navigate('/booking');
      }
  
    }, [navigate, selectedTanggal, selectedDay, selectedJam, selectedLayanan]);

    const backToBooking = () => {
        navigate('/booking');
    }

    const createBooking = async () => {
        const uid = localStorage.getItem('uid');

        if (uid === null) {
            navigate('/signin');
            return;
        }
      
        try {
            const bookingId = generateRandomCode(16);
            const bookingHarga = generateRandomHarga(harga);
        
            await addDoc(collection(db, "booking"), {
                id: bookingId,
                tanggal: selectedTanggal,
                day: selectedDay,
                jam: selectedJam,
                layanan: selectedLayanan,
                harga: bookingHarga,
                user: uid,
                status: 'pending',
            });

            navigate('/profile');
      
        } catch (error) {
            alert(error);
        }
      };
      
      const generateRandomHarga = (baseHarga) => {
        return baseHarga + 2000 + Math.floor(Math.random() * 1000);
      };
      
      const generateRandomCode = (length) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const rnd = Math.random;
      
        for (let i = 0; i < length; i++) {
          result += chars.charAt(Math.floor(rnd() * chars.length));
        }
      
        return result;
      };

    return (
        <>
            <Navbar/>
            <div className="min-h-screen p-5 bg-pink-50">
                <div className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-6">
                <div className="flex justify-between items-center mb-6">
                    <button className="text-blue-500">
                    <i className="fas fa-arrow-left"></i>
                    </button>
                    <h1 className="text-2xl font-bold text-pink-200">Confirm Booking</h1>
                    <div className="text-transparent">
                    <i className="fas fa-heart-broken"></i>
                    </div>
                </div>

                <div className="mb-6">
                    <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold">Tanggal</h2>
                    <button onClick={backToBooking} className="text-blue-500 text-sm">
                        Ubah
                    </button>
                    </div>
                    <div className="flex items-center mt-2">
                    <i className="fas fa-calendar-alt text-blue-500 text-2xl"></i>
                    <p className="ml-4 text-lg">{`${selectedTanggal} | ${selectedDay} | ${selectedJam}`}</p>
                    </div>
                </div>

                <div className="mb-6">
                    <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold">Layanan</h2>
                    <button onClick={backToBooking} className="text-blue-500 text-sm">
                        Ubah
                    </button>
                    </div>
                    <div className="flex items-center mt-2">
                    <i className="fas fa-tasks text-blue-500 text-2xl"></i>
                    <p className="ml-4 text-lg">{selectedLayanan}</p>
                    </div>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-semibold">Pembayaran</h2>
                    <div className="flex justify-between mt-2">
                    <span className="text-sm text-gray-500">{selectedLayanan}</span>
                    <span className="text-sm font-semibold">Rp {harga}</span>
                    </div>
                    <div className="flex justify-between mt-2">
                    <span className="text-sm text-gray-500">Biaya Admin</span>
                    <span className="text-sm font-semibold">Rp 2000</span>
                    </div>
                    <div className="flex justify-between mt-4">
                    <span className="text-lg">Total</span>
                    <span className="text-lg font-semibold text-blue-500">Rp {harga + 2000}</span>
                    </div>
                </div>

                <button
                    onClick={createBooking}
                    className="w-full bg-blue-500 text-white py-3 rounded-lg mt-4 hover:bg-blue-600 transition-all duration-200"
                >
                    Booking
                </button>
                </div>
            </div>
            <Footer/>
        </>
    );
}