export default function BookingContainer({ key, pesanan }) {
    return (
      <div className="p-8 bg-white shadow-md rounded-lg">
        <div className="flex items-center mb-4">
          <i className="fas fa-medkit text-douceBase text-5xl"></i>
          <div className="ml-5">
            <h2 className="text-xl font-bold">{pesanan.layanan}</h2>
          </div>
        </div>
  
        <hr className="border-gray-300 mb-4" />
  
        <div className="flex justify-between mb-4">
          <div>
            <div className="flex items-center mb-2">
              <i className="fas fa-calendar-alt"></i>
              <p className="ml-3">{pesanan.tanggal} - {pesanan.day}</p>
            </div>
            <div className="flex items-center">
              <i className="fas fa-wallet"></i>
              <p className="ml-3">Rp {pesanan.harga}</p>
            </div>
          </div>
          <div>
            <div className="flex items-center mb-2">
              <i className="fas fa-clock"></i>
              <p className="ml-3">{pesanan.jam}</p>
            </div>
            <div className="flex items-center">
              <i className="fas fa-tachometer-alt"></i>
              <p className="ml-3">Pending</p>
            </div>
          </div>
        </div>
  
        <div className="space-y-3">
          <button 
            className="w-full bg-pink-50 text-black font-semibold py-3 rounded-full border-black border"
            onClick={() => window.location.href = `/payment?pay=${pesanan.harga}`}
          >
            Cara Bayar
          </button>
          <button 
            className="w-full bg-white border-2 text-pink-200 font-semibold border-pink-200 py-3 rounded-full"
          >
            Batalkan Pesanan
          </button>
        </div>
      </div>
    );
  }