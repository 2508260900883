import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import LandingPage from "./View/LandingPage";
import DownloadPage from "./View/DownloadPage";
import AboutPage from "./View/AboutPage";
import SignInPage from "./View/SignInPage";
import SignUpPage from "./View/SignUpPage";
import ProfilePage from "./View/ProfilePage";
import RegisterMitraPage from "./View/RegisterMitraPage";
import ConfirmPaymentPage from "./View/ConfirmPayment";
import BookingDoulaPage from "./View/BookingDoula";
import ConfirmBookingPage from "./View/ConfirmBooking";
import ChatPage from "./View/ChatPage";
import PrivacyPolicyPage from "./View/PrivacyPolicyPage";

export default function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path = "/" element={<LandingPage/>}/> 
        <Route path = "/download" element={<DownloadPage/>}/>
        <Route path = "/about" element={<AboutPage/>}/>
        <Route path = "/signin" element={<SignInPage/>}/>
        <Route path = "/signup" element={<SignUpPage/>}/>
        <Route path = "/profile" element={<ProfilePage/>}/>
        <Route path = "/register" element={<RegisterMitraPage/>}/>
        <Route path = "/payment" element={<ConfirmPaymentPage/>}/>
        <Route path = "/booking" element={<BookingDoulaPage/>}/>
        <Route path = "/confirm-booking" element={<ConfirmBookingPage/>}/>
        <Route path = "/chat" element={<ChatPage/>}/>
        <Route path = "/privacypolicy" element={<PrivacyPolicyPage/>}/>
      </>
    )
  )

  return (
    <RouterProvider router={router} />
  );
}

