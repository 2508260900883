import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function BookingDoulaPage () {
  const [selectedTanggal, setSelectedTanggal] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedJam, setSelectedJam] = useState('');
  const [selectedLayanan, setSelectedLayanan] = useState('');
  const [harga, setHarga] = useState(0);

  const navigation = useNavigate();

  useEffect(() => {

      const uid = localStorage.getItem('uid');

      if (uid === null) {
        navigation('/signin');
      } 

  }, [navigation]);

  const dates = [
    new Date(Date.now() + 2 * 86400000),
    new Date(Date.now() + 3 * 86400000),
    new Date(Date.now() + 4 * 86400000),
    new Date(Date.now() + 5 * 86400000),
    new Date(Date.now() + 6 * 86400000),
  ];

  const handleNext = () => {
    if (!selectedDay || !selectedJam || !selectedTanggal || !selectedLayanan ) {
      alert('Pastikan semua data terisi');
    } else {
      navigation('/confirm-booking', {
        state: {
          selectedTanggal,
          selectedDay,
          selectedJam,
          selectedLayanan,
          harga,
        },
      });
    }
  };

  const jamContainer = (time) => (
    <div
      onClick={() => setSelectedJam(time)}
      className={`cursor-pointer px-4 py-2 rounded-lg border ${
        selectedJam === time
          ? 'bg-pink-500 text-white'
          : 'border-blue-500 text-black'
      }`}
    >
      {time}
    </div>
  );

  const calendarContainer = (day, date) => (
    <div
      key={date}
      onClick={() => {
        setSelectedTanggal(date);
        setSelectedDay(day);
      }}
      className={`cursor-pointer p-4 rounded-lg ${
        date === selectedTanggal ? 'bg-blue-500 text-white' : 'bg-red-500 text-white'
      }`}
    >
      <div>{day}</div>
      <div className="font-bold text-lg">{date}</div>
    </div>
  );

  const layananContainer = (title, subtitle, icon, harga) => (
    <div
      onClick={() => {
        setSelectedLayanan(title);
        setHarga(harga);
      }}
      className={`p-4 rounded-lg shadow-md flex justify-between items-center cursor-pointer ${
        selectedLayanan === title ? 'border border-blue-500' : ''
      }`}
    >
      <div className="flex items-center">
        <div>
          <div className="text-lg font-bold">{title}</div>
          <div className="text-sm text-gray-500">{subtitle}</div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-2">Rp {harga}</div>
        <FontAwesomeIcon
            icon={selectedLayanan === title ? faCheckCircle : faCircle}
            className={`text-blue-500 transition-all duration-200 ${selectedLayanan === title ? 'text-blue-500' : 'text-gray-300'}`}
        />
      </div>
    </div>
  );

  return (
    <>
        <Navbar/>
        <div className="min-h-screen bg-pink-50 p-8">
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
                <div className="flex justify-center items-center mb-8">
                    <h1 className="text-xl md:text-3xl font-bold">Booking Doula</h1>
                </div>

                <div className="mb-12">
                <h2 className="text-xl md:text-2xl font-medium mb-4">Pilih Tanggal</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
                    {dates.map(date =>
                    calendarContainer(format(date, 'E'), format(date, 'd'))
                    )}
                </div>
                </div>

                <div className="mb-12">
                <h2 className="text-2xl font-bold mb-4">Pilih Jam</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                    {['09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM'].map(jam => 
                    jamContainer(jam)
                    )}
                </div>
                </div>

                <div className="mb-12">
                <h2 className="text-2xl font-bold mb-4">Pilih Layanan</h2>
                <div className="flex flex-col gap-4">
                    {layananContainer('Chat', '1 Jam', 'chat', 30000)}
                    {layananContainer('Call / Video Call', '1 Jam', 'video_call', 50000)}
                </div>
                </div>

                <button
                onClick={handleNext}
                className="w-full py-4 bg-blue-500 text-white text-xl font-bold rounded-lg hover:bg-blue-600 transition-all duration-200"
                >
                Selanjutnya
                </button>
            </div>
        </div>
        <Footer/>
    </>
  );
};
