import { useEffect, useState } from "react";
import topbar from '../Assets/topbar.png'
import '../index.css'
import { Link } from "react-router-dom";

export default function Navbar() {

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isUser, setIsUser] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {

    const uid = localStorage.getItem('uid');

    if(uid != null){
      setIsUser(true);
    }
  }, []);


  return (
    <nav id="header" className="w-full z-30 top-0 text-black px-2 md:px-6 py-2 bg-custom-gradient">
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0">
        <div className="pl-4 flex items-center">
          <div
            className="toggleColour text-black no-underline hover:no-underline font-bold"
          >
            <Link to={'/'}>
              <img src={topbar} className="w-36" alt="logo"/>
            </Link>
          </div>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            onClick={toggleNav}
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`lg:flex lg:items-center lg:w-auto ${
            isNavOpen ? 'block' : 'hidden'
          } absolute lg:relative top-16 lg:top-0 right-0 w-full lg:w-auto bg-white lg:bg-transparent shadow-lg lg:shadow-none p-4 lg:p-0`}
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
          <li className="mr-3">
            <div
              className="block py-2 px-4 text-black font-medium rounded-lg hover:bg-gray-200 transition duration-300"
            >
              <Link to={'/'}>Home</Link>
            </div>
          </li>
          <li className="mr-3">
            <div
              className="block py-2 px-4 text-black font-medium rounded-lg hover:bg-gray-200 transition duration-300"
            >
              <Link to={'/about'}>About Us</Link>
            </div>
          </li>
          <li className="mr-3">
            <div
              className="block py-2 px-4 text-black font-medium rounded-lg hover:bg-gray-200 transition duration-300"
            >
              <Link to={'/privacypolicy'}>Privacy Policy</Link>
            </div>
          </li>
          <li className="mr-3">
            <div
              className="block py-2 px-4 text-black font-medium rounded-lg hover:bg-gray-200 transition duration-300"
            >
              <Link to={'/download'}>Download</Link>
            </div>
          </li>
          {
            isUser ? (
              <li className="mr-3">
                <div
                  className="block py-2 px-4 text-black font-medium rounded-lg hover:bg-gray-200 transition duration-300"
                >
                  <Link to={'/profile'}>Profile</Link>
                </div>
              </li>
            ) :           <li className="mr-3">
            <div
              className="block py-2 px-4 text-black font-medium rounded-lg hover:bg-gray-200 transition duration-300"
            >
              <Link to={'/signin'}>Sign In</Link>
            </div>
          </li>
          }
          </ul>
        </div>
      </div>
    </nav>
  );

}