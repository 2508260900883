import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../Util/Firebase";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function SignInPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate()
  
    const handleSubmit = (e) => {
      e.preventDefault();

      if(email === '' || password === ''){
        alert('Please fill in all fields')
        return
      }

      try {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            localStorage.setItem('uid', user.uid);
            navigate('/profile')
        })
        .catch((error) => {
            alert("Failed To Sign In")
        })
      }catch(error){
        alert(error.message)
      }
    };

    return (
        <>
            <Navbar/>
            <div className="min-h-screen flex items-center justify-center bg-pink-50 px-2">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6 text-center text-pink-300">Sign In</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </div>
                    <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="password">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    </div>
                    <button
                    type="submit"
                    onClick={handleSubmit}
                    className="w-full bg-blue-500 text-white font-bold py-3 rounded-lg hover:bg-blue-600 transition duration-300"
                    >
                    Sign In
                    </button>
                </form>
                <p className="text-center text-sm text-gray-600 mt-4">
                    Don't have an account?{' '}
                    <Link to="/signup" className="text-blue-500 hover:underline">
                    Sign Up
                    </Link>
                </p>
                </div>
            </div>
            <Footer/>
        </>
    );
}