import { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { collection, doc, query, orderBy, onSnapshot, addDoc } from 'firebase/firestore';
import { db } from "../Util/Firebase";

export default function ChatPage(){

    
    const queryParams = new URLSearchParams(window.location.search);
    const doulaId = queryParams.get('doulaId');
    const userId = queryParams.get('userId');
    const chatId = String(userId) + String(doulaId);

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const getChat = () => {
        const chatRef = doc(collection(db, 'chat'), chatId);
        const messagesRef = collection(chatRef, 'messages');
        const q = query(messagesRef, orderBy('time', 'asc'));

        onSnapshot(q, (snapshot) => {
            const messagesList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(messagesList);
        });
    };

    const sendMessage = async () => {
        if (newMessage.trim() === '') return;

        
        try {
            const chatRef = doc(db, 'chat', chatId);
            const messagesRef = collection(chatRef, 'messages');
            
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            await addDoc(messagesRef, {
                sender: userId,
                message: newMessage,
                time: currentTime,
            });
    
            setNewMessage('');
        } catch (error) {
            console.log("Error sending message:", error);
        }
    };

    useEffect(() => {
        const uid = localStorage.getItem('uid');

        if(uid === null || uid !== userId){
            window.location.href = '/profile';
            return;
        }

        getChat();

    }, [chatId]);

    const handleSendMessage = () => {
        sendMessage();
    };
    
    return (
        <>
            <Navbar/>
            <div className="min-h-screen flex flex-col bg-gray-100">
                <div className="flex flex-col flex-1 mx-auto bg-white shadow-lg rounded-lg overflow-hidden my-4 w-full max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
                    <div className="flex items-center justify-between p-6 bg-blue-600 text-white">
                        <h2 className="text-xl font-semibold">Chat with Support</h2>
                    </div>
                    <div className="flex-1 p-6 overflow-y-auto">
                        {messages.map((message, index) => (
                            <div
                                key={message.id}
                                className={`flex mb-4 ${
                                    message.sender === userId ? 'justify-end' : 'justify-start'
                                }`}
                            >
                                <div
                                    className={`max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl p-4 rounded-lg ${
                                        message.sender === userId
                                            ? 'bg-blue-600 text-white'
                                            : 'bg-gray-200 text-gray-800'
                                    }`}
                                >
                                    {message.message}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="p-4 border-t border-gray-200 bg-white">
                        <div className="flex items-center">
                            <input
                                type="text"
                                className="flex-1 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                                placeholder="Type your message..."
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                            />
                            <button
                                onClick={handleSendMessage}
                                className="ml-4 bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-all duration-200"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
      );
}