export default function RiwayatContainer({ key, active }) {
    return (
      <div className="p-8 bg-white shadow-md rounded-lg">
        <div className="flex items-center mb-4">
          <i className="fas fa-medkit text-douceBase text-5xl"></i>
          <div className="ml-5">
            <h2 className="text-xl font-bold">{active.layanan}</h2>
            <p className="text-lg font-bold">{active.namaDoula}</p>
          </div>
        </div>
  
        <hr className="border-gray-300 mb-4" />
  
        <div className="flex justify-between mb-4">
          <div>
            <div className="flex items-center mb-2">
              <i className="fas fa-calendar-alt"></i>
              <p className="ml-3">{active.tanggal} - {active.day}</p>
            </div>
            <div className="flex items-center">
              <i className="fas fa-wallet"></i>
              <p className="ml-3">Rp {active.harga}</p>
            </div>
          </div>
          <div>
            <div className="flex items-center mb-2">
              <i className="fas fa-clock"></i>
              <p className="ml-3">{active.jam}</p>
            </div>
            <div className="flex items-center">
              <i className="fas fa-tachometer-alt"></i>
              <p className="ml-3">Finished</p>
            </div>
          </div>
        </div>
      </div>
    );
  }