import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import app from '../Assets/about_app.png';
import background from '../Assets/about_background.png';
import title from '../Assets/about_title.png';
import doctor from '../Assets/about_doctor.png';
import tujuan from '../Assets/about_tujuan.png';
import mockup from '../Assets/about_mockup.png';

export default function AboutPage() {
    return (
        <div className="bg-pink-50">
            <Navbar/>
            <div className="w-full h-full flex flex-col items-center px-6 md:px-12 lg:px-18">
                <img src={title} className="my-4 max-w-full md:max-w-lg" alt="error"/>

                <img src={background} className="w-full h-auto object-cover mb-4 md:max-w-3xl" alt="error"/>

                <div class="container px-4 sm:px-16 md:px-24 lg:px-32 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center md:gap-8">
                    <img src={app} className="w-full md:w-1/3 h-auto" alt="error"/>
                    <img src={mockup} className="w-full md:w-1/3 h-auto" alt="error"/>
                </div>

                <div class="container mb-4 px-4 sm:px-16 md:px-24 lg:px-32 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center md:gap-8">
                    <img src={doctor} className="w-full md:w-1/3 h-auto" alt="error"/>
                    <img src={tujuan} className="w-full md:w-1/3 h-auto" alt="error"/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}