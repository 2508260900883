import { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { db, storage} from "../Util/Firebase";
import { doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export default function RegisterMitraPage() {

    const uid = localStorage.getItem('uid');

    useEffect(() => {
        if(uid === null){
            window.location.href = '/login';
            return;
        }
    })

    const [formData, setFormData] = useState({
        name: '',
        nik: '',
        nohp: '',
        biography: '',
        job: '',
        gender: '',
        education: '',
        image: null,
    });
    
    const handleChange = (e) => {
        setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        });
    };
    
    const handleImageChange = (e) => {
        setFormData({
        ...formData,
        image: e.target.files[0],
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            formData.image === null ||
            formData.name === '' ||
            formData.nik === '' ||
            formData.nohp === '' ||
            formData.biography === '' ||
            formData.job === ''
        ) {
            alert('Please fill all the fields');
            return;
        }

        try {

            const filePath = `mitra/${uid}`;
            const storageRef = ref(storage, filePath);
            await uploadBytes(storageRef, formData.image);

            const downloadUrl = await getDownloadURL(storageRef);

            await setDoc(doc(db, 'mitra', uid), {
                name: formData.name,
                nik: formData.nik,
                nohp: formData.nohp,
                biografi: formData.biography,
                pekerjaan: formData.job,
                pendidikan: formData.education,
                jenisKelamin: formData.gender,
                image: downloadUrl,
                rating: 5.0,
                saldo: 0,
            });

            await setDoc(doc(db, 'register', uid), {
                registerConfirmed: false,
                payment: 150000 + new Date().getTime() % 1000
            });

            alert('Form submitted successfully!');

        } catch (error) {
            console.error("Error uploading file or saving data: ", error);
            window.location.href('/profile')
        }
    };
    
    return (
        <>
            <Navbar />
            <div className="flex justify-center items-center min-h-screen bg-pink-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md my-8">
                    <h2 className="text-2xl font-bold mb-6 text-center text-pink-200">Mitra Registration</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-gray-700">Name</label>
                        <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Enter your name"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">NIK</label>
                        <input
                        type="text"
                        name="nik"
                        value={formData.nik}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Enter your NIK"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">No HP</label>
                        <input
                        type="text"
                        name="nohp"
                        value={formData.nohp}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Enter your phone number"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Biography</label>
                        <textarea
                        name="biography"
                        value={formData.biography}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder="Tell us about yourself"
                        rows="4"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Job</label>
                        <select
                        name="job"
                        value={formData.job}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        >
                            <option value="">Select your job</option>
                            <option value="dokter">Dokter</option>
                            <option value="perawat">Perawat</option>
                            <option value="pns">PNS</option>
                            <option value="tni">TNI</option>
                            <option value="polri">POLRI</option>
                            <option value="pegawai_swasta">Pegawai Swasta</option>
                            <option value="wiraswasta">Wiraswasta</option>
                            <option value="petani">Petani</option>
                            <option value="nelayan">Nelayan</option>
                            <option value="buruh">Buruh</option>
                            <option value="lainnya">Lainnya</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Gender</label>
                        <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        >
                        <option value="">Select your gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Education</label>
                        <select
                        name="education"
                        value={formData.education}
                        onChange={handleChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        >
                        <option value="">Select your education</option>
                        <option value="SD">SD</option>
                        <option value="SMP">SMP</option>
                        <option value="SMA">SMA</option>
                        <option value="D3">D3</option>
                        <option value="S1">S2</option>
                        <option value="S1">S2</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Profile Picture</label>
                        <input
                        type="file"
                        onChange={handleImageChange}
                        className="w-full mt-1 p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:outline-none"
                        >
                        Register
                        </button>
                    </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </>
    );
}