
import { useEffect, useState } from "react";
import profile from "../Assets/blank-profile.png";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { auth, db, storage } from "../Util/Firebase";
import { signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, getDocs, where, collection, query } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ActiveContainer from "../Components/ActiveContainer";
import PekerjaanContainer from "../Components/PekerjaanContainer";
import BookingContainer from "../Components/BookingContainer";
import RiwayatContainer from "../Components/RiwayatContainer";

export default function ProfilePage() {
    const [activeTab, setActiveTab] = useState("Profil");
    const [username, setUsername] = useState('');
    const [image, setImage] = useState('');
    const [editUsername, setEditUsername] = useState('');
    const [imageUrl] = useState("");
    const [isDoula, setIsDoula] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [registerPayment, setRegisterPayment] = useState(0);

    const navigation = useNavigate();

    useEffect(() => {

      const uid = localStorage.getItem('uid');

      const fetchUserData = async () => {
        if (uid === null) {
          navigation('/signin');
        } else {
          try {
            const userDocRef = doc(db, "user", uid);
            const userDoc = await getDoc(userDocRef);
  
            if (userDoc.exists()) {
              const userData = userDoc.data();

              setUsername(userData.username || "");
              setImage(userData.image || null);
              setEditUsername(userData.username || "");
              setIsDoula(userData.isDoula || false);
              
            } else {
              console.log("No such document!");
            }

            const doulaDocRef = doc(db, "register", uid);
            const doulaDoc = await getDoc(doulaDocRef);

            if (doulaDoc.exists()) {
              setIsRegister(true);
              console.log(doulaDoc.data());
              setRegisterPayment(doulaDoc.data().payment);
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      };
  
      fetchUserData();
    }, [navigation]);

    const [activeData, setActiveData] = useState([]);
    const [pekerjaanData, setPekerjaanData] = useState([]);
    const [riwayatData, setRiwayatData] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    
    useEffect(() => {
      const fetchData = async () => {
          try {

            const uid = localStorage.getItem('uid');

            const activeQuery = query(
              collection(db, 'active'),
              where('user', '==', uid)
            );
            const activeSnapshot = await getDocs(activeQuery);
            const activeList = activeSnapshot.docs.map(doc => doc.data());
            setActiveData(activeList);
  
            const pekerjaanQuery = query(
              collection(db, 'pekerjaan'),
              where('user', '==', uid)
            );
            const pekerjaanSnapshot = await getDocs(pekerjaanQuery);
            const pekerjaanList = pekerjaanSnapshot.docs.map(doc => doc.data());
            setPekerjaanData(pekerjaanList);
  
            const riwayatQuery = query(
              collection(db, 'riwayat'),
              where('user', '==', uid)
            );
            const riwayatSnapshot = await getDocs(riwayatQuery);
            const riwayatList = riwayatSnapshot.docs.map(doc => doc.data());
            setRiwayatData(riwayatList);

            const bookingQuery = query(
              collection(db, 'booking'),
              where('user', '==', uid)
            );
            const bookingSnapshot = await getDocs(bookingQuery);
            const bookingList = bookingSnapshot.docs.map(doc => doc.data());
            setBookingData(bookingList);
  
          } catch (error) {
            console.error("Error fetching data: ", error);
          }
      };
  
      fetchData();
    }, []);

    const onLogOutClick = () => {
      signOut(auth).then(() => {
        localStorage.removeItem('uid');
        navigation('/signin');
      })
    }

    const handleFileChange = (e) => {
      if (e.target.files[0]) {
        setImage(e.target.files[0]);
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();

      const uid = localStorage.getItem('uid');
      const userDocRef = doc(db, "user", uid);

      try {
        let imageURL = imageUrl;

        if (image) {
          const storageRef = ref(storage, `profile_images/${uid}`);
          await uploadBytes(storageRef, image);
          imageURL = await getDownloadURL(storageRef);
        }

        await updateDoc(userDocRef, {
          username: editUsername,
          image: imageURL,
        });

        window.location.reload();
      } catch (error) {
        alert(error);
      }
    };
    
    const renderContent = () => {
        switch (activeTab) {
          case "Order":
            return (
                <div className="space-y-5 justify-center">
                  <Link to="/booking">
                    <button className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75">
                      Booking Doula
                    </button>
                  </Link>
                  
                  {
                    activeData.map((item) => (
                      <ActiveContainer key={item.id} active = {item} />
                    ))
                  }

                  {
                    pekerjaanData.map((item) => (
                      <PekerjaanContainer key={item.id} pesanan = { item }/>
                    ))
                  }

                  {
                    bookingData.map((item) => (
                      < BookingContainer key={item.id} pesanan = { item }/>
                    ))
                  }

                </div>
            );
          case "Riwayat":
            return (
              <div className="space-y-5 justify-center">
                <Link to="/booking">
                  <button className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75">
                    Booking Doula
                  </button>
                </Link>

                {
                    riwayatData.map((item) => (
                      <RiwayatContainer active = { item }/>
                    ))
                  }
                
              </div>
          );
          case "Profil":
            return (
              <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">

                {
                  !isDoula? (
                    <div className="flex justify-center my-4">
                      <Link to={isRegister ? `/payment?pay=${registerPayment}` : "/register"}>
                        <button className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75">
                          Become Doula Now !
                        </button>
                      </Link>
                    </div>
                  ) : null
                }

                <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">Edit Profile</h1>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Username</label>
                    <input
                      type="text"
                      value={editUsername}
                      onChange={(e) => setEditUsername(e.target.value)}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Enter your username"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Profile Image</label>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                    />
                    {imageUrl && (
                      <div className="mt-4">
                        <img
                          src={imageUrl}
                          alt="Profile"
                          className="rounded-full w-24 h-24 object-cover mx-auto"
                        />
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Save Changes
                  </button>
                </form>
              </div>
            );
          default:
            return <div></div>;
        }
      };
    
      return (
        <>
            <Navbar/>
            <div className="container mx-auto p-6 min-h-screen sm:p-8 lg:p-12 bg-pink-50">

              <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-6">
                  <img
                  src={image || profile}
                  alt="profile"
                  className="w-24 h-24 sm:w-32 sm:h-32 rounded-full mx-auto sm:mx-0"
                  />
                  <div className="text-center sm:text-left mt-4 sm:mt-0">
                  <h1 className="text-3xl lg:text-4xl font-bold text-gray-800">{username}</h1>
                  <button
                      onClick={onLogOutClick}
                      className="mt-4 bg-red-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300">Log Out</button>
                  </div>
              </div>
        
              <div className="mt-8 sm:mt-10">
                  <nav className="flex justify-center sm:justify-start space-x-6 border-b-2 border-gray-200 pb-2">
                  <button
                      onClick={() => setActiveTab("Profil")}
                      className={`${
                      activeTab === "Profil" ? "border-b-4 border-blue-500 text-blue-500" : "text-gray-600"
                      } py-2 transition-all duration-300 ease-in-out hover:text-blue-500 focus:outline-none`}
                  >
                      Profil
                  </button>
                  <button
                      onClick={() => setActiveTab("Order")}
                      className={`${
                      activeTab === "Order" ? "border-b-4 border-blue-500 text-blue-500" : "text-gray-600"
                      } py-2 transition-all duration-300 ease-in-out hover:text-blue-500 focus:outline-none`}
                  >
                      Order
                  </button>
                  <button
                      onClick={() => setActiveTab("Riwayat")}
                      className={`${
                      activeTab === "Riwayat" ? "border-b-4 border-blue-500 text-blue-500" : "text-gray-600"
                      } py-2 transition-all duration-300 ease-in-out hover:text-blue-500 focus:outline-none`}
                  >
                      Riwayat
                  </button>
                  </nav>
              </div>
        
              <div className="mt-8 sm:mt-10">
                  <div className="bg-white shadow rounded-lg p-6">
                  {renderContent()}
                  </div>
              </div>
            </div>
            <Footer/>
        </>
      );
}