import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

export default function PrivacyPolicyPage() {
    return (
        <>
            <Navbar/>
            <div className="min-h-screen bg-pink-50 py-10 px-5 lg:px-20">
                <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
                    <h1 className="text-3xl font-bold text-pink-200 mb-6">Privacy Policy</h1>
                    
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                    <p className="text-gray-700">
                        Welcome to Momsie! Momsie is an application dedicated to helping mothers through their pregnancy journey by providing reliable information and access to doula services. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to ensure your information is protected.
                    </p>
                    </section>
            
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
                    <p className="text-gray-700">
                        We collect personal information to provide and improve our services. The types of information we collect include:
                    </p>
                    <ul className="list-disc list-inside text-gray-700 mt-2">
                        <li><strong>Personal Information:</strong> Name, email address, phone number, and due date.</li>
                        <li><strong>Health Information:</strong> Pregnancy details, medical history related to pregnancy, and preferences for doula services.</li>
                        <li><strong>Usage Data:</strong> Information about how you use the Momsie app, including access times, pages viewed, and the actions taken on the app.</li>
                    </ul>
                    </section>
            
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
                    <p className="text-gray-700">
                        The information we collect is used to:
                    </p>
                    <ul className="list-disc list-inside text-gray-700 mt-2">
                        <li>Provide personalized content and recommendations based on your pregnancy stage.</li>
                        <li>Connect you with certified doulas and manage your bookings.</li>
                        <li>Send you updates and notifications related to pregnancy health and services.</li>
                        <li>Analyze app usage to improve our services.</li>
                    </ul>
                    </section>
            
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">4. Third-Party Services</h2>
                    <p className="text-gray-700">
                        Momsie may share your information with third-party service providers to assist in delivering our services, such as payment processing, analytics, and customer support. We ensure that these third parties adhere to strict data protection standards.
                    </p>
                    </section>
            
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">5. Security of Your Information</h2>
                    <p className="text-gray-700">
                        We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, loss, or misuse. However, no system can be completely secure, and we cannot guarantee the absolute security of your information.
                    </p>
                    </section>
            
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">6. How to Delete Your Account</h2>
                    <p className="text-gray-700">
                        If you wish to delete your Momsie account, you can do so by following these steps:
                    </p>
                    <ul className="list-decimal list-inside text-gray-700 mt-2">
                        <li>Contact Our Email at momsiedoula2024@gmail.com .</li>
                        <li>Provide your account detail</li>
                        <li>Wait for our team to confirm and delete your account</li>
                    </ul>
                    <p className="text-gray-700 mt-2">
                        Once your account is deleted, all your personal information will be removed from our servers. Please note that some information may remain in backup storage for a limited time due to legal or technical reasons.
                    </p>
                    </section>
            
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">7. Changes to This Privacy Policy</h2>
                    <p className="text-gray-700">
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes through the app or via email. Your continued use of Momsie after the changes take effect will indicate your acceptance of the updated policy.
                    </p>
                    </section>
            
                    <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
                    <p className="text-gray-700">
                        If you have any questions or concerns about this Privacy Policy or the practices of Momsie, please contact us at momsiedoula2024@gmail.com .
                    </p>
                    </section>
                </div>
            </div>
            <Footer/>
        </>
    );
}