import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import ovo from "../Assets/ovo.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function ConfirmPaymentPage() {

    const queryParams = new URLSearchParams(window.location.search);
    const uid = localStorage.getItem('uid');
    const payment = queryParams.get('pay');

    useEffect (() => {
        if(uid === null){
            window.location.href = '/login';
            return;
        }
    })



    return (
        <>
            <Navbar/>
            <div className="min-h-screen flex flex-col items-center bg-pink-50">
                
                <div className="w-full max-w-md mt-8 px-4">
                <div className="w-full p-4 bg-white rounded-lg shadow-md">
                    <div className="flex justify-between items-center">
                    <img
                        src={ovo}
                        alt="OVO"
                        className="h-6 w-auto"
                    />
                    <div className="flex flex-col">
                        <span className="text-lg font-bold text-blue-500">
                        081234567890
                        </span>
                        <span className="text-lg font-bold text-blue-500">
                        A/N Miranda
                        </span>
                    </div>
                    </div>
                </div>
        
                <div className="mt-8 text-center">
                    <p className="text-2xl font-bold text-black">
                    Rp {payment}
                    </p>
                </div>
        
                <div className="mt-8 text-center">
                    <p className="text-base font-bold text-black">
                    Please make the payment to the account above. Once done, our team will verify your account within 1 x 12 hours.
                    </p>
                </div>
        
                <div className="mt-8">
                    <Link to={"/profile"}>
                        <button
                        className="w-full py-3 bg-white text-blue-500 font-bold rounded-lg border border-blue-500 shadow-md hover:bg-blue-500 hover:text-white transition duration-300"
                        >
                            Back to Home
                        </button>
                    </Link>
                </div>
                </div>
            </div>
            <Footer/>
        </>
    );
  };