import landing_1 from "../Assets/landing_1.png";
import doula from "../Assets/doula.png";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import edukasi from "../Assets/edukasi.png";
import beranda from "../Assets/beranda.png";
import obat from "../Assets/obat.png";
import { Link } from "react-router-dom";

export default function LandingPage() {
    return (
        <div className="bg-pink-50">
            <Navbar />
            <div class="container mx-auto px-8 py-4 my-8">
                <div class="flex flex-col lg:flex-row items-center lg:items-start justify-center gap-6 md:gap-12 lg:gap-24">
                    <div class="lg:mb-0 justify-center">
                        <img src={landing_1} alt="Landing" className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl"/>
                    </div>
                    <div class="flex justify-center lg:justify-end">
                        <img 
                            src={doula} 
                            alt="Placeholder"
                            class="w-full max-w-sm lg:max-w-md"
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center">
                <button className="flex items-center bg-red-400 text-white font-bold py-3 px-6 rounded-full shadow-lg hover:bg-blue-600 transition duration-300 ease-in-out gap-2">
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                    </svg>
                    Explore More
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                    </svg>
                </button>
            </div>
            <section className="flex flex-col items-center w-full text-center text-white py-8">
                <div className="flex flex-col items-center w-full max-w-md mx-auto gap-2">
                    <div className="w-full">
                        <img src={edukasi} className="w-full h-auto object-cover md:my-4 px-4" alt="Edukasi" />
                    </div>
                    <div className="w-full">
                        <img src={obat} className="w-full h-auto object-cover md:my-4 px-4" alt="Obat" />
                    </div>
                    <div className="w-full">
                        <Link to={"/booking"}>
                            <img src={beranda} className="w-full h-auto object-cover mt-6 px-4" alt="Beranda" />
                        </Link>
                    </div>
                </div>
            </section>
            <div className="h-16"></div>
            <Footer />
        </div>
    );
}