import mockup from '../Assets/mockup.png'
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import download from '../Assets/download_mockup.png'

export default function DownloadPage() {

    const stats = [
        { icon: '👥', label: 'Member', value: '350++' },
        { icon: '🚴‍♂️', label: 'Mitra', value: '50++' },
        { icon: '📥', label: 'Download', value: '1k++' },
        { icon: '💳', label: 'Payments', value: 'Rp 2.500.000++' },
    ];

    return (
        <div className="bg-pink-50">
            <Navbar/>

            <div class="container mt-6 px-4 sm:px-16 md:px-24 lg:px-32 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center">
                
                <div class="w-full xl:w-3/5 flex justify-center overflow-y-hidden">
                    <img class="w-auto lg:mr-0 slide-in-bottom" src={download} alt="Momsie App Mockup"/>
                </div>

                <div class="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden mb-8 md:mb-0">
                    <span>
                        <span class="my-2 text-xl sm:text-2xl md:text-4xl text-pink-300 font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
                            Momsie&nbsp;
                        </span>

                        <span class="my-2 text-xl sm:text-2xl md:text-3xl text-black font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
                            Available On Mobile!
                        </span>
                    </span>

                    <p class="leading-normal text-sm md:text-base my-2 md:text-left slide-in-bottom-subtitle">
                        Momsie kini tersedia di mobile! Unduh aplikasi Momsie di Google Play dan App Store untuk pengalaman terbaik dalam merawat kesehatan Bunda dan Si Kecil, kapan saja dan di mana saja. 
                    </p>
                    <p className="font-semibold"> 
                        #MomsieOnMobile #KesehatanBunda #DoulaTerbaik
                    </p>
                    <p class="text-blue-400 font-bold mt-4 text-center md:text-left fade-in">
                        Download Momsie App:
                    </p>
                    <div class="flex w-full justify-center md:justify-start pb-6 lg:pb-0 fade-in">
                        <a href="https://firebasestorage.googleapis.com/v0/b/doula-6b8a2.appspot.com/o/app-release.apk?alt=media&token=0f90e033-c80d-4199-904f-a14f907ca284" class="bg-blue-500 flex justify-center items-center gap-1 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out my-2">
                            Download Now
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center my-8 p-6">
                <div className="w-full max-w-6xl rounded-2xl bg-pink-200 p-6 md:p-8 shadow-md">
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                    <div className="mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-800 mb-2">
                        Helping a local business reinvent itself
                        </h2>
                        <p className="text-gray-600 text-sm md:text-base">
                        We reached here with our hard work and dedication
                        </p>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col items-center md:block">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {stats.map((stat, index) => (
                            <div
                                key={index}
                                className="flex items-center space-x-2 md:space-x-4 p-3 md:p-4 rounded-lg transition"
                            >
                                <span className="text-2xl">{stat.icon}</span>
                                <div>
                                <p className="text-lg font-semibold text-gray-800">
                                    {stat.value}
                                </p>
                                <p className="text-xs text-gray-600">
                                    {stat.label}
                                </p>
                                </div>
                            </div>
                            ))}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            <div class="container mt-6 px-8 sm:px-16 md:px-24 lg:px-32 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center">
                <div class="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden mb-8 md:mb-0">
                    <h1 class="my-2 text-xl sm:text-2xl md:text-4xl text-purple-800 font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
                        Empower Your Pregnancy Journey with Momsie
                    </h1>
                    <p class="leading-normal text-base md:text-xl my-2 text-center md:text-left slide-in-bottom-subtitle">
                        Experience personalized doula support at your fingertips. Momsie is your companion for a healthier, happier pregnancy.
                    </p>
                    <p class="text-blue-400 font-bold mt-4 text-center md:text-left fade-in">
                        Download Momsie App:
                    </p>
                    <div class="flex w-full justify-center md:justify-start pb-6 lg:pb-0 fade-in">
                        <a href="https://firebasestorage.googleapis.com/v0/b/doula-6b8a2.appspot.com/o/app-release.apk?alt=media&token=0f90e033-c80d-4199-904f-a14f907ca284" class="bg-blue-500 flex justify-center items-center gap-1 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out my-2">
                            Download Now
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                            </svg>
                        </a>
                    </div>
                </div>

                <div class="w-full xl:w-3/5 flex justify-center overflow-y-hidden">
                    <img class="w-5/6 mx-auto lg:mr-0 slide-in-bottom" src={mockup} alt="Momsie App Mockup"/>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

// <div class="flex flex-col items-center md:items-start text-center md:text-left space-y-1 my-2">
// <p class="text-gray-700 text-lg font-semibold">
//     <span class="text-blue-600">10,000++</span> Downloads
// </p>

// <p class="text-gray-700 text-lg font-semibold">
//     <span class="text-blue-600">2,400++</span> Active Users
// </p>
// </div>